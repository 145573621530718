import { PermissionActions } from '@/constants/PermissionActions';
import { AppModules } from '@/constants/AppModules';

const routePermissions: Record<string, string[]> = {
  // Clients Section
  '/clients': ['Customers.Read'],
  '/clients/edit-client': ['Customers.Update'],
  '/clients/new-client': ['Customers.Create'],
  '/clients/view-client': ['Customers.Read'],

  // Enquiries Section
  '/enquiries': ['Requests.Read'],
  '/enquiries/edit-enquiry': ['Requests.Update'],
  '/enquiries/new-enquiry': ['Requests.Create'],
  '/enquiries/view-enquiry': ['Requests.Read'],

  // Expenses Section
  '/expenses': ['Expenses.Read'],

  // Inventories Section
  '/inventories': [`${AppModules.INVENTORIES}.${PermissionActions.Read}`],
  '/inventories/main-inventory': [
    `${AppModules.INVENTORIES}.${PermissionActions.View_Main_Inventory}`,
  ],
  '/inventories/van-inventory': [
    `${AppModules.INVENTORIES}.${PermissionActions.View_Van_Inventory}`,
  ],

  // Invoices Section
  '/invoices': ['Invoicing.Read'],
  '/invoices/edit-invoice': ['Invoicing.Update'],
  '/invoices/new-invoice': ['Invoicing.Create'],
  '/invoices/view-invoice': ['Invoicing.Read'],

  // Jobs Section
  '/jobs': ['Jobs.Read'],
  '/jobs/edit-job': ['Jobs.Update'],
  '/jobs/new-job': ['Jobs.Create'],
  '/jobs/view-job': ['Jobs.Read'],

  // Products & Services Section
  '/products-services': ['Products_Services.Read'],

  // Purchase Orders Section
  '/purchase-orders': ['Purchase_Orders.Read'],
  '/purchase-orders/edit-purchase-order': ['Purchase_Orders.Update'],
  '/purchase-orders/new-purchase-order': ['Purchase_Orders.Create'],
  '/purchase-orders/view-purchase-order': ['Purchase_Orders.Read'],

  // Quotes Section
  '/quotes': ['Quotes.Read'],
  '/quotes/edit-quote': ['Quotes.Update'],
  '/quotes/new-quote': ['Quotes.Create'],
  '/quotes/view-quote': ['Quotes.Read'],

  // Reports Section
  '/reports': ['Reports.Read'],

  // Settings Section
  '/settings': ['Settings.Read'],

  // Suppliers Section
  '/suppliers': ['Suppliers.Read'],
  '/suppliers/edit-supplier': ['Suppliers.Update'],
  '/suppliers/new-supplier': ['Suppliers.Create'],
  '/suppliers/view-supplier': ['Suppliers.Read'],

  // Teams Section
  '/teams': ['Team_Members.Read'],
  '/teams/edit-team': ['Team_Members.Update'],
  '/teams/new-team': ['Team_Members.Create'],
  '/teams/view-team': ['Team_Members.Read'],

  // Timesheets Section
  '/timesheets': ['Timesheets.Read'],
};

export default routePermissions;
